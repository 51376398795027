import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
// context
import { CartContext } from "../contexts/CartContext.js";
import { ProductContext } from "../contexts/ProductContext.js";

import { Container, Grid, Typography, Button, Box, Paper } from "@mui/material";
import { size } from "lodash";

import logoLovely from '../img/lovely.png'
export const ProductDetails = () => {
  // obtener el id del producto por medio de la url
  const { id } = useParams();
  const { products } = useContext(ProductContext);
  const { addToCart } = useContext(CartContext);

  const [cantidad, setCantidad] = useState(1)
    const cursiva2 ='../fonts/Nautilus.otf'

  // Obtener el producto basado en el id
  const product = products.find((item) => item.id === parseInt(id));

  if (!product) {
    return (
      <section className="h-screen flex justify-center items-center">
        Cargando...
      </section>
    );
  }

  // Desestructurar el producto
  const { nombre, precioVenta, categoria, imagen } = product;

  // const images = [
  //   {
  //     itemImageSrc: "https://via.placeholder.com/400",
  //     thumbnailImageSrc: "https://via.placeholder.com/100",
  //     alt: "Image 1",
  //   },
  //   {
  //     itemImageSrc: "https://via.placeholder.com/400",
  //     thumbnailImageSrc: "https://via.placeholder.com/100",
  //     alt: "Image 2",
  //   },
  //   {
  //     itemImageSrc: "https://via.placeholder.com/400",
  //     thumbnailImageSrc: "https://via.placeholder.com/100",
  //     alt: "Image 3",
  //   },
  //   {
  //     itemImageSrc: "https://via.placeholder.com/400",
  //     thumbnailImageSrc: "https://via.placeholder.com/100",
  //     alt: "Image 4",
  //   },
  // ];

  // const responsiveOptions = [
  //   {
  //     breakpoint: "1024px",
  //     numVisible: 5,
  //   },
  //   {
  //     breakpoint: "768px",
  //     numVisible: 3,
  //   },
  //   {
  //     breakpoint: "560px",
  //     numVisible: 1,
  //   },
  // ];

  return (
    <section className="pt-32 pb-12 lg:py-32 h-screen flex">
      <div className="container mx-auto">
        <Container maxWidth="lg" sx={{ padding: { xs: 2, sm: 4, md: 6 } }}>
          <Grid container spacing={4}>
            {/* Sección de imágenes con Galleria */}
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                alt={nombre}
                src={size(imagen) > 1 ? imagen :logoLovely }
                sx={{maxHeight: 400}}
              />
            </Grid>

            {/* Sección de información del producto */}
            <Grid item xs={12} md={6} style={{ }}>
              <Typography
                variant="h5"
                component="h1"
                gutterBottom
                sx={{ fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" }, wordBreak: 'break-word'}}
              >
                {nombre}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                style={{fontFamily: cursiva2}}
                sx={{ fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" }}}
                className="cursiva"
              >
                {categoria}
              </Typography>
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{
                  textDecoration: "line-through",
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" },
                }}
              >
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" } , color: '#9c27b0' }}
              >
                L. {precioVenta}{" "}
                {/* <span style={{ color: "orange", fontSize: 18 }}>50% Off</span> */}
              </Typography>

              {/* Controles de cantidad */}
              <Grid
                container
                alignItems="center"
                spacing={2}
                sx={{ marginTop: 2 }}
              >
                <Grid item>
                  <Button style={{borderColor: '#9c27b0', color: 'black'}} variant="outlined" onClick={() => setCantidad(cantidad-1)}>-</Button>
                </Grid>
                <Grid item>
                  <Typography variant="h6">{cantidad}</Typography>
                </Grid>
                <Grid item>
                  <Button style={{borderColor: '#9c27b0', color: "black"}} variant="outlined" onClick={() => setCantidad(cantidad+1)}>+</Button>
                </Grid>
              </Grid>

              {/* Botón de añadir al carrito */}
              <Button
                variant="contained"
                size="large"  
                sx={{
                  background: '#9c27b0',
                  color: 'white',
                  marginTop: 2,
                  width: { xs: "100%", md: "auto" },
                  fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                }}
                onClick={() => addToCart(product, product.id, cantidad)}
              >
                Agregar al carrito
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </section>
  );
};

{
  /* <Card sx={{ display: "flex" }}>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={imagen}
        alt="Live from space album cover"
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            Live From Space
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            Mac Miller
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}></Box>
      </Box>
    </Card> */
}

//   <div className="flex flex-col lg:flex-row items-center">
//   {/* imagenn */}
//   <div className="flex flex-1 justify-center items-center mb-8 lg:mb-0">
//     <img
//       className="max-w-[200px] max-h-[450px] lg:max-w-sm rounded-l-2xl"
//       src={imagen}
//       alt={nombre}
//     />
//   </div>
//   {/* texto */}
//   <div className="flex-1 text-center lg:text-left">
//     <h1 className="text-[26px] font-medium mb-2 max-w-[450px] mx-auto lg:mx-0">
//       {nombre}
//     </h1>
//     <div className="text-xl text-red-500 font-medium mb-6">
//       $ {precioVenta}
//     </div>
//     <p className="mb-8">{descripcion}</p>
//     <button
//       onClick={() => addToCart(product, product.id)}
//       className="bg-primary py-4 px-8 text-white"
//     >
//       Agregar al carrito
//     </button>
//   </div>
// </div>
