import { useContext } from "react";
import { Link } from "react-router-dom";
// iconos
import { IoMdAdd, IoMdClose, IoMdRemove } from "react-icons/io";
// context
import { CartContext } from "../contexts/CartContext.js";

export const CartItem = ({ item }) => {
  const { removeFromCart, increaseAmount, decreaseAmount } =
    useContext(CartContext);
  // desestructurar item
  const { id, nombre, imagen, precioVenta, amount } = item;
  return (
    <div className="flex gap-x-4 py-2 lg:px-1 border-b border-gray-200 w-full font-light text-gray-500 border-[#000000]">
      <div className="w-full min-h-[150px] flex items-center gap-x-4">
        <Link to={`/product/${id}`}>
          <img className="max-w-[80px]" src={imagen} alt={nombre} />
        </Link>
        <div className="w-full flex flex-col">
          <div className="flex justify-between mb-2">
            <Link
              to={`/product/${id}`}
              className="text-sm uppercase font-medium max-w-[240px] text-primary hover:underline"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 3, // Número de líneas que quieres mostrar
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {nombre}
            </Link>
            {/* icono para remover */}
            <div
              onClick={() => removeFromCart(id)}
              className="text-xl cursor-pointer"
            >
              <IoMdClose className="text-gray-500 hover:text-red-500 transition" />
            </div>
          </div>

          <div className="flex gap-x-2 h-[36px] text-sm ">
            {/* cantidad */}
            <div className="flex flex-1 max-w-[100px] items-center h-full border text-primary font-medium border-[#000000]" >
              {/* disminuir */}
              <div
                onClick={() => decreaseAmount(id)}
                className="flex-1 h-full flex justify-center items-center cursor-pointer "
              >
                <IoMdRemove />
              </div>
              <div className="h-full flex justify-center items-center px-2">
                {amount}
              </div>
              {/* aumentar */}
              <div
                onClick={() => increaseAmount(id)}
                className="flex-1 h-full flex justify-center items-center cursor-pointer"
              >
                <IoMdAdd />
              </div>
            </div>

            {/* precio */}
            <div className="flex flex-1 items-center justify-around ">
              L. {precioVenta}
            </div>
            {/* total */}
            {/* con 2 decimales */}
            <div className="flex-1 flex justify-end items-center text-primary font-medium">{`L. ${parseFloat(
              precioVenta * amount
            ).toFixed(2)}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
