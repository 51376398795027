import { useEffect, useRef } from 'react';
import { Player } from '@lordicon/react';
import ICON from './wired-lineal-146-trolley-hover-jump.json'

export default function Carrito() {    
  const playerRef = useRef(null);
  
  useEffect(() => {
      playerRef.current?.playFromBeginning();
  }, [])

  return (
    <div style={{flex: 1, gap: 20, backgroundColor: "FFF", alignItems: "center", justifyContent: 'center', flexDirection: 'column'}}>
      <Player 
        ref={playerRef} 
        size={30} 
        icon={ ICON }
        onComplete={() => playerRef.current?.playFromBeginning()}
      />
    </div>
  );
}
