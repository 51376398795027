import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
// contexts
import CartProvider from "./contexts/CartContext.js";
import ProductProvider from "./contexts/ProductContext.js";
import SidebarProvider from "./contexts/SidebarContext.js";
import reportWebVitals from './reportWebVitals.js';
import "./index.css"
ReactDOM.createRoot(document.getElementById("root")).render(
  <SidebarProvider>
    <CartProvider>
      <ProductProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ProductProvider>
    </CartProvider>
  </SidebarProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
