import React from "react";

export const Footer = () => {
  return (
    <footer className="bg-primary py-5">
      <div className="container mx-auto">
        <p className="text-white text-right">
          Copyright &copy; LinkSolutionsHN. Todos los derechos reservados.
        </p>
        <p href="https://lordicon.com/" className="text-xs  ">
          Icons by Lordicon.com
        </p>
      </div>
    </footer>
  );
};
